import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import DashBoard from '../views/Dashboard.vue';
import DashBoardLayout from '../layout/DashboardLayout.vue';
import Content from '../layout/Content.vue';
import store from '@/store';
import { bannerPathList } from '@/domain/banner';
import { couponPathList } from '@/domain/coupon';
import { authorityPathList } from '@/domain/authority';
import { serialPathList } from '@/domain/serial';
import { productPathList } from '@/domain/product';
import { pointPathList } from '@/domain/point';
import ComponentTestContainer from '@/views/ComponentTestContainer.vue';
import { qnaPathList } from '@/domain/qna';
import { hostPathList, hostAdminPathList } from '@/domain/host';
import { userPathList } from '@/domain/user';
import { keywordPathList } from '@/domain/keyword';
import { uspPathList } from '@/domain/usp';
import { shortPathList } from '@/domain/shortcut';
import { noticePathList } from '@/domain/notice';
import { faqsPathList } from '@/domain/faqs';
import { productReviewPathList } from '@/domain/productReview';
import { mobilePathList } from '@/domain/mobile';
import { categoryPathList } from '@/domain/category';
import { introPopupPathList } from '@/domain/introPopup';
import {
  loginPathList,
  passwordResetPathList,
  signUpPathList,
} from '@/domain/login';
import { isHostAdmin } from '@/env';
import { collectionPathList } from '@/domain/collection';
import { areaPathList } from '@/domain/area';
import { magazineList } from '@/domain/magazine';
import { inspectionPathList } from '@/domain/inspection';
import { bookingPathList } from '@/domain/booking';
import { purchasePathList } from '@/domain/purchase';
import { hotKeywordPathList } from '@/domain/hotkeyword';
import { faqPathList } from '@/domain/faq';
import { orderPathList } from '@/domain/order';
import { notificationMessagePathList } from '@/domain/notificationMessage';
import { staticInfoPathList } from '@/domain/staticInfo';
import HostAdminDashboard from '../views/HostAdminDashboard.vue';
import { refundPathList } from '@/domain/refund';
import { deliveryPathList } from '@/domain/delivery';
import { billPathList } from '@/domain/bill';
import { settlementPathList } from '@/domain/settlement';
import { socialFeedPathList } from '@/domain/socialFeed';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: DashBoardLayout,
    children: [
      {
        path: '/',
        component: Content,
        children: [
          {
            path: '/',
            name: 'Dashboard',
            redirect: isHostAdmin() ? '' : '/product/list',
            //어드민
            meta: { showFooter: true },
            component: isHostAdmin() ? HostAdminDashboard : undefined,
          },
        ],
      },
      bannerPathList,
      uspPathList,
      collectionPathList,
      qnaPathList,
      couponPathList,
      authorityPathList,
      serialPathList,
      productPathList,
      inspectionPathList,
      hostPathList,
      userPathList,
      pointPathList,
      productReviewPathList,
      socialFeedPathList,
      keywordPathList,
      shortPathList,
      mobilePathList,
      noticePathList,
      faqsPathList,
      categoryPathList,
      introPopupPathList,
      hostAdminPathList,
      areaPathList,
      magazineList,
      bookingPathList,
      purchasePathList,
      hotKeywordPathList,
      faqPathList,
      notificationMessagePathList,
      orderPathList,
      staticInfoPathList,
      refundPathList,
      deliveryPathList,
      billPathList,
      settlementPathList,
    ],
  },
  passwordResetPathList,
  signUpPathList,
  loginPathList,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth === false)) {
    next();
  } else {
    if (
      isHostAdmin() &&
      to.matched.find(record => record.meta.title === '프립 목록') &&
      !store.getters.isHostLoggedIn
    ) {
      console.log('invalid host');
      next('/login');
      return;
    }

    if (store.getters.isLoggedIn) {
      next();
      return;
    }

    next('/login');
  }
});

export default router;
